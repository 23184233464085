import React, {useEffect} from 'react';

const ChatInput = ({
    input,
    inputElem,
    onChangeInput,
    onSubmit
}) => {
    useEffect(() => {
        inputElem.current.focus();
    }, [inputElem]);
    const onChange = e => onChangeInput(e.target.value);
    return (
        <>
            <form className="d-flex chat-content" onSubmit={onSubmit}>
                <div className="text-input-sec">
                    <input ref={inputElem} type="text" className="text-input" value={input} onChange={onChange} placeholder="메세지를 입력하세요." />
                </div>
                <button type="submit" className="chat-enter">보내기</button>
            </form>
        </>
    );
};

export default ChatInput;