import React, {useState} from 'react';
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Loading = ({onLoading}) => {
    let [color, setColor] = useState("#FA233B");

    return (
        <div className="page-loading">
            <div className="sweet-loading">
                <BounceLoader color={color} loading={onLoading} css={override} size={30} />
            </div>
        </div>
    )
};

export default Loading;