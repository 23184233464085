import React from 'react';

const ChatTyping = ({memberTyping}) => {
    return (
        <>
            {memberTyping ? <div className="text-ing">
                <div className="text-ing2">
                    <p className="f-s11">입력 중...</p>
                </div>
            </div> : ''}
        </>
    );
}

export default ChatTyping;