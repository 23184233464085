import {createAction, handleActions} from 'redux-actions';
import * as sendbird from '../api/sendbird';
import * as artemis from '../api/artemis';
import produce, {enableES5} from 'immer';
import {getDateTime, getTimestamp} from '../lib/util';

// immer 사용하려면 ie 때문에 enableES5() 적용해야함
enableES5();

const INIT_CHAT_SCREEN              = 'chat/INIT_CHAT_SCREEN';
const CLEAR_MESSAGE_LIST            = 'chat/CLEAR_MESSAGE_LIST';
const MORE_MESSAGE_LIST             = 'chat/MORE_MESSAGE_LIST';
const GET_MESSAGE_LIST_ACTION       = 'chat/GET_MESSAGE_LIST_ACTION';
const MESSAGE_LIST_SUCCESS          = 'chat/MESSAGE_LIST_SUCCESS';
const MESSAGE_LIST_FAIL             = 'chat/MESSAGE_LIST_FAIL';
const SEND_MESSAGE_TEMPORARY        = 'chat/SEND_MESSAGE_TEMPORARY';
const SEND_MESSAGE_SUCCESS          = 'chat/SEND_MESSAGE_SUCCESS';
const SEND_MESSAGE_FAIL             = 'chat/SEND_MESSAGE_FAIL';
const SEND_MESSAGE_COUNT_UP         = 'chat/SEND_MESSAGE_COUNT_UP';
const SEND_MESSAGE_COUNT_RESET      = 'chat/SEND_MESSAGE_COUNT_RESET';
const SAVE_MESSAGE_DB_SUCCESS       = 'chat/SAVE_MESSAGE_DB_SUCCESS';
const SAVE_MESSAGE_DB_ERROR         = 'chat/SAVE_MESSAGE_DB_ERROR';
const SEND_TYPING_START_SUCCESS     = 'chat/SEND_TYPING_START_SUCCESS';
const SEND_TYPING_START_FAIL        = 'chat/SEND_TYPING_START_FAIL';
const SEND_TYPING_END_SUCCESS       = 'chat/SEND_TYPING_END_SUCCESS';
const SEND_TYPING_END_FAIL          = 'chat/SEND_TYPING_END_FAIL';
const MESSAGE_RECEIVED              = 'chat/MESSAGE_RECEIVED';
const MESSAGE_UPDATED               = 'chat/MESSAGE_UPDATED';
const MESSAGE_DELETED               = 'chat/MESSAGE_DELETED';
const MESSAGE_DELETED_SUCCESS       = 'chat/MESSAGE_DELETED_SUCCESS';
const MESSAGE_DELETED_FAIL          = 'chat/MESSAGE_DELETED_FAIL';
const TYPING_STATUS_UPDATED         = 'chat/TYPING_STATUS_UPDATED';
const READ_RECEIPT_UPDATED          = 'chat/READ_RECEIPT_UPDATED';
const ADMIN_MESSAGE                 = 'chat/ADMIN_MESSAGE';
const ADMIN_MESSAGE_SUCCESS         = 'chat/ADMIN_MESSAGE_SUCCESS';
const ADMIN_MESSAGE_ERROR           = 'chat/ADMIN_MESSAGE_ERROR';

const CHANGE_INPUT          = 'chat/CHANGE_INPUT';
const INSERT                = 'chat/INSERT';
const CHANGE_EDIT_DONE      = 'chat/CHANGE_EDIT_DONE';
const SCROLL_DOWN           = 'chat/SCROLL_DOWN';

export const initChat       = createAction(INIT_CHAT_SCREEN);
export const changeInput    = createAction(CHANGE_INPUT, input=>input);
export const changeEditDone = createAction(CHANGE_EDIT_DONE, editDone=>editDone);
export const sendMessageTimeReset = createAction(SEND_MESSAGE_COUNT_RESET);

export const saveMessageDb = (message) => async dispatch => {
    try {
        await artemis.saveMessage(message);
        dispatch({type: SAVE_MESSAGE_DB_SUCCESS});
    } catch (e) {
        dispatch({type: SAVE_MESSAGE_DB_ERROR});
        /*
        에러나서 데이터 다시 db 저장 전송시키면 무한루프 돌 경우가 생김
        dispatch(saveMessageDb(message));*/
    }
};
export const onSendButton = (userInfo, channelUrl, textMessage) => async (dispatch, getState) => {
    try {
        const chatInfo = getState().connectChat.chatInfo;
        const channel = await sendbird.sbGetGroupChannel(channelUrl);
        sendTextMessage(dispatch, userInfo, chatInfo, channel, textMessage);
    } catch (e) {
        dispatch({ type: SEND_MESSAGE_FAIL });
        // TODO: 개발자도구에서 강제 삭제시 오류가 발생함 :: element 가 없으니까.. 이부분 해결해야함??
        dispatch({ type: 'popup/SHOW_POPUP', payload:{message: '메세지를 전송하지 못했습니다.'} });
    }
};
const sendTextMessage = (dispatch, userInfo, chatInfo, channel, textMessage) => {
    const messageTemp = sendbird.sbSendTextMessage(channel, textMessage, async (message, error) => {
        if (error) {
            dispatch({ type: SEND_MESSAGE_FAIL });
        } else {
            if (message && message._sender && message._sender.userId) {
                message.chatDate = chatInfo.startTime;
                message.user = (message._sender.userId === userInfo.callee.ac_id) ? 'callee' : 'caller';
                message.readMessage = false;
                message.st_code = userInfo.st_code;
                message.caller = userInfo.caller.ac_id;
                message.callee = userInfo.callee.ac_id;
                dispatch({type: SEND_MESSAGE_SUCCESS, payload: message});
                dispatch({
                    type: CHANGE_EDIT_DONE,
                    payload: true
                });
                dispatch({
                    type: SEND_MESSAGE_COUNT_UP
                });
                dispatch(saveMessageDb(message));
            } else {
                dispatch({ type: SEND_MESSAGE_FAIL });
            }
        }
    });
    dispatch({
        type: SEND_MESSAGE_TEMPORARY,
        message: messageTemp
    });
};
/**
 *
 * @param data
 * @returns {function(*, *): Promise<void>}
 */
export const adminMessage = (data) => async (dispatch, getState) => {
    const userInfo = getState().connectUser.userInfo;
    const channel = userInfo.channel;
    const message = {
        chatDate: data.startTime,
        messageId: data.messageId,
        messageType: 'admin',
        channelUrl: channel,
        channelType: 'group',
        sendingStatus: 'succeeded',
        user: 'admin',
        message: data.message,
        createdAt: new Date().getTime(),
    };
    dispatch({type: ADMIN_MESSAGE_SUCCESS, payload: message});
    dispatch({type:'chat/SCROLL_DOWN'});
    const saveMessage = {
        ...message,
        st_code: userInfo.st_code,
        caller: userInfo.caller.ac_id,
        callee: userInfo.callee.ac_id
    };
    try {
        await artemis.saveMessage(saveMessage);
        dispatch({type: SAVE_MESSAGE_DB_SUCCESS});
    } catch (e) {
        dispatch({type: SAVE_MESSAGE_DB_ERROR});
    }
}
/*export const adminMessage = createAction(ADMIN_MESSAGE_SUCCESS, chat => chat);*/

export const sbMarkAsRead = ({ channelUrl, channel }) => {
    if (channel) {
        channel.markAsRead();
    } else {
        sendbird.sbGetGroupChannel(channelUrl).then(channel => channel.markAsRead());
    }
};

export const sbIsTyping = channel => {
    if (channel.isTyping()) {
        const typingMembers = channel.getTypingMembers();
        if (typingMembers.length === 1) {
            return `${typingMembers[0].nickname} is typing...`;
        } else {
            return 'several member are typing...';
        }
    } else {
        return '';
    }
};

export const typingStart = channelUrl => async dispatch => {
    try {
        await sendbird.sbTypingStart(channelUrl);
        dispatch({type: SEND_TYPING_START_SUCCESS});
    } catch (e) {
        dispatch({type: SEND_TYPING_START_FAIL});
    }
}

export const typingEnd = channelUrl => async dispatch => {
    try {
        await sendbird.sbTypingEnd(channelUrl);
        dispatch({type: SEND_TYPING_END_SUCCESS});
    } catch (e) {
        dispatch({type: SEND_TYPING_END_FAIL});
    }
}
/**
 * ArtemisApi 서버 db 에서 이전 데이터 가져오기
 * @param channelUrl
 * @param userInfo
 * @returns {function(*, *): Promise<*>}
 */
export const getPrevMessageListDb = (channelUrl, userInfo) => async (dispatch, getState) => {

}
/**
 * 샌드버드에서 이전 데이터 가져오기
 * @param channelUrl
 * @param userInfo
 * @param listMore :: default false
 * @returns {function(*, *): Promise<boolean|undefined>}
 */
export const getPrevMessageList = (channelUrl, userInfo, listMore = false) => async (dispatch, getState) => {
    let previousMessageListQuery = null;
    try {
        previousMessageListQuery = await sendbird.sbCreatePreviousMessageListQuery(channelUrl);
    } catch (e) {
        dispatch({ type: MESSAGE_LIST_FAIL });
        dispatch(prevListMore(false));
        return false;
    }
    if (previousMessageListQuery && previousMessageListQuery.hasMore) {
        try {
            const {sb, channel} = getState().connectChat.chatInfo;
            const messages = getState().chat.chat;
            let chat = {};
            if (!listMore) {
                // 처음 시작시
                chat = await sendbird.sbGetMessageList(previousMessageListQuery);
            } else {
                // 더보기
                if (messages.length > 0 && messages[0].messageId) {
                    chat = await sendbird.sbGetMessagesByMessageId(channel, messages[0].messageId);
                } else {
                    chat = await sendbird.sbGetMessageList(previousMessageListQuery);
                }
            }
            const other  = channel.members.filter(user => user.userId !== sb.userId);
            if (Array.isArray(chat) && chat.length > 0) {
                const listChat = chat.map(chat => {
                    if (chat.hasOwnProperty('_sender') && chat._sender.hasOwnProperty('userId')) {
                        chat.user = (chat._sender.userId === userInfo.callee.ac_id) ? 'callee' : 'caller';
                        chat.readMessage = chat._sender.userId !== sb.userId || other[0].connectionStatus === 'online' || chat.createdAt <= other[0].lastSeenAt;
                        /*if (chat._sender.userId !== sb.userId || other[0].connectionStatus === 'online' || chat.createdAt <= other[0].lastSeenAt) {
                            chat.readMessage = true;
                        } else {
                            chat.readMessage = false;
                        }*/
                    } else {
                        chat.user = 'admin';
                        chat.readMessage = true;
                    }
                    return chat;
                });
                dispatch({
                    type: MESSAGE_LIST_SUCCESS,
                    payload: listChat
                });
                if (!listMore || messages.length === 0) {
                    dispatch({
                        type: 'chat/SCROLL_DOWN'
                    });
                } else {
                    dispatch(prevListAction(false));
                }
                dispatch(prevListMore(true));
            } else {
                dispatch(prevListMore(false));
            }
            dispatch(prevListAction(false));
        } catch (e) {
            dispatch({ type: MESSAGE_LIST_FAIL });
        }

    } else {
        dispatch({ type: MESSAGE_LIST_FAIL });
    }
}
/**
 * 채팅 삭제
 * 사용 안 하기로 함. 추후 어떻게 변경될지는 모름..
 * @param channel
 * @param message
 * @returns {function(*): Promise<void>}
 */
export const deleteChat = (channel, message) => async dispatch => {
    try {
        await sendbird.sbDeleteMessage(channel, message);
        dispatch({type: MESSAGE_DELETED_SUCCESS, payload: message.messageId});
    } catch (e) {
        dispatch({type: MESSAGE_DELETED_FAIL});
    }
}
/**
 *
 * @type {function(): {type: *}}
 */
export const clearMessage = createAction(CLEAR_MESSAGE_LIST);
export const prevListAction = createAction(GET_MESSAGE_LIST_ACTION, status => status);
export const prevListMore = createAction(MORE_MESSAGE_LIST, status => status);
/**
 * input: 입력되는 메세지
 * editDone: 입력 완료 여부
 * chat 채팅 메세지 리스트
 * lastSendTime 마지막 글쓴 시간 :: 너무 빠른 메세지 입력 방지하기위해
 * lastSendTimeCnt 같은시간에 몇개까지 보냈는지
 * error, error_msg 에러메시지 저장
 * scroll 상태 변경될때마다 스크롤 다운시킴
 * chat [] => sendbird chat message
 */
const initialState = {
    input: '',
    editDone: false,
    memberTyping: '',
    chat: [],
    lastSendTime: '',
    lastSendTimeCnt: 0,
    error: false,
    error_msg: '',
    scroll: false,
    prevList: false,
    prevListAction: false,
};

const chat = handleActions(
    {
        [CHANGE_INPUT]: (state, {payload: input}) => produce(state, draft => {
            draft.editDone = false;
            draft.input = input
        }),
        [CHANGE_EDIT_DONE]: (state, {payload: editDone}) => produce(state, draft => {
            draft.editDone = editDone;
        }),
        [INSERT]: (state, {payload: chat}) => produce(state, draft => {
            draft.chat.push(chat);
        }),
        [TYPING_STATUS_UPDATED]: (state, {payload: memberTyping}) => produce(state, draft => {
            draft.memberTyping = memberTyping;
        }),
        [CLEAR_MESSAGE_LIST]: (state) => produce(state, draft => {
           draft.chat = [];
        }),
        [MORE_MESSAGE_LIST]: (state, {payload: status}) => produce(state, draft => {
            draft.prevList = status;
        }),
        [GET_MESSAGE_LIST_ACTION]: (state, {payload: status}) => produce(state, draft => {
            draft.prevListAction = status;
        }),
        [MESSAGE_LIST_SUCCESS]: (state, {payload: chat}) => produce(state, draft => {
            //draft.chat = [];
            for (let i = 0; i < chat.length; i++) {
                //draft.chat.push(chat[i]);
                draft.chat.unshift(chat[i]);
            }
        }),
        [MESSAGE_RECEIVED]: (state, {payload: chat}) => produce(state, draft => {
            draft.chat.push(chat);
        }),
        [SEND_MESSAGE_SUCCESS]: (state, {payload: message}) => produce(state, draft => {
            draft.chat.push(message);
            draft.lastSendTime = getTimestamp();
        }),
        [SEND_MESSAGE_FAIL]: (state, {payload: error}) => produce(state, draft => {
           draft.error = true;
           draft.error_msg = error;
        }),
        [ADMIN_MESSAGE]: (state) => ({
            ...state,
        }),
        [ADMIN_MESSAGE_SUCCESS]: (state, {payload: message}) => produce(state, draft => {
            draft.chat.push(message);
        }),
        [ADMIN_MESSAGE_ERROR]: (state) => ({
            ...state,
        }),
        [SCROLL_DOWN]: (state) => ({
            ...state,
            scroll: !state.scroll
        }),
        [SEND_MESSAGE_COUNT_UP]: (state) => ({
            ...state,
            lastSendTimeCnt: state.lastSendTimeCnt + 1
        }),
        [SEND_MESSAGE_COUNT_RESET]: (state) => ({
            ...state,
            lastSendTimeCnt: 0
        }),
        [READ_RECEIPT_UPDATED]: (state) => {
            return {
                ...state,
                chat: state.chat.map( chat => {
                    if (!chat.readMessage) {
                        return {
                            ...chat,
                            readMessage: true
                        }
                    } else {
                        return { ...chat}
                    }
                })
            }
        },
        [MESSAGE_DELETED_SUCCESS]: (state, {payload: messageId}) => produce(state, draft => {
            const index = draft.chat.findIndex(chat => chat.messageId === messageId);
            if (index >= 0) {
                draft.chat.splice(index, 1);
            }
        }),
        [MESSAGE_DELETED]: (state, {payload: messageId}) => produce(state, draft => {
            const index = draft.chat.findIndex(chat => chat.messageId === messageId);
            if (index >= 0) {
                draft.chat.splice(index, 1);
            }
        }),
        [MESSAGE_DELETED_FAIL]: (state) => ({
            ...state,
        })
    },
    initialState,
);

export default chat;
