import {createAction, handleActions} from 'redux-actions';
import * as hermes from '../api/hermes';

const GET_USER_COIN             = 'timeExtension/GET_USER_COIN';
const GET_USER_COIN_SUCCESS     = 'timeExtension/GET_USER_COIN_SUCCESS';
const GET_USER_COIN_ERROR       = 'timeExtension/GET_USER_COIN_ERROR';
const OPEN_POPUP                = 'timeExtension/OPEN_POPUP';
const CLOSE_POPUP               = 'timeExtension/CLOSE_POPUP';
const ADD_TIME                  = 'timeExtension/ADD_TIME';
const ADD_TIME_SUCCESS          = 'timeExtension/ADD_TIME_SUCCESS';
const ADD_TIME_ERROR            = 'timeExtension/ADD_TIME_ERROR';

export const setUserCoin    = createAction(GET_USER_COIN_SUCCESS, myCoin=>myCoin);
export const openPopup      = createAction(OPEN_POPUP);
export const closePopup     = createAction(CLOSE_POPUP);

export const openTimeExtensionPopup = (channelUrl) => async dispatch => {
    // 코인부터 가져옴.
    dispatch({type: 'connectChat/LOADING'});
    dispatch({type: GET_USER_COIN});
    try {
        const response = await hermes.getUserCoin(channelUrl);
        dispatch(setUserCoin({myCoin: response.remain_coin, itCoinPrice: response.coin_price}));
        dispatch(openPopup());

    } catch (e) {
        dispatch({type: GET_USER_COIN_ERROR});
    }
    dispatch({type: 'connectChat/LOADING_END'});
}

export const setTimeExtension = (channelUrl, userId, duration, useCoin) => async dispatch => {
    dispatch({type: 'connectChat/LOADING'});
    dispatch ({type: ADD_TIME});
    try {
        await hermes.setAddTime(channelUrl, userId, duration, useCoin);
        dispatch({type: ADD_TIME_SUCCESS});
        dispatch(closePopup());
        dispatch({type: 'connectChat/LOADING_END'});
    } catch (e) {
        const payload  = {
            title: '상담시간 연장',
            message: e
        }
        dispatch({type: 'connectChat/LOADING_END'});
        dispatch({type: 'popup/SHOW_POPUP', payload});
        dispatch({type: ADD_TIME_ERROR});
    }
}


const initialState = {
    timeExtensionVisible: false,
    myCoin: 0,
    itCoinPrice: 0
}

const timeExtension = handleActions(
    {
        [GET_USER_COIN]: (state) => ({
            ...state
        }),
        [GET_USER_COIN_SUCCESS]: (state, {payload}) => ({
            ...state,
            myCoin: payload.myCoin,
            itCoinPrice: payload.itCoinPrice
        }),
        [OPEN_POPUP]: (state) => ({
            ...state,
            timeExtensionVisible: true
        }),
        [CLOSE_POPUP]: (state) => ({
            ...state,
            timeExtensionVisible: false
        }),
        [ADD_TIME]: (state) => ({
            ...state,
        }),
        [ADD_TIME_SUCCESS]: (state) => ({
            ...state,
        }),
        [ADD_TIME_ERROR]: (state) => ({
            ...state,
        }),
    },
    initialState
)

export default timeExtension;