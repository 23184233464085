import React, {useCallback} from 'react';
import {numberWithCommas} from "../../lib/util";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {popupClose} from "../../modules/popup";

const TimeExtensionError = () => {
    const myCoin = useSelector(state => state.timeExtension.myCoin, shallowEqual);
    const dispatch = useDispatch();
    const onPopupClose = useCallback(() => {
        dispatch(popupClose());
    }, [dispatch]);

    return (
        <div className="popup-bg">
            <div className="popup-div">
                <div className="popup-top">
                    <div>
                        <h2 className="f-s16">상담시간 연장</h2>
                    </div>
                    <div>
                        <button type="button" className="popup-btn-close c-btn-close" onClick={onPopupClose}>닫기</button>
                    </div>
                </div>
                <div className="popup-center">
                    <div className="sangdam-inner-none">
                        <p className="m24b">보유 코인이 부족하여<br />상담시간 연장이 불가합니다.</p>
                        <div className="d-flex">
                            <p className="s-center">· 나의 코인</p>
                            <div className="sangdam-inner-coin">
                                <p className="en-breck">{numberWithCommas(myCoin)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-bottom">
                    <div className="popup-bottom-inner">
                        <button type="button" className="popup-bottom-btn c-btn-close" onClick={onPopupClose}>확인</button>
                    </div>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
};

export default TimeExtensionError;