import React, {useEffect} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import '../assets/css/popup.css';
import AlertClosePopup from "../components/popup/AlertClosePopup";
import ChatEndPopup from "../components/popup/ChatEndPopup";
import ChatClosePopup from "../components/popup/ChatClosePopup";
import TimeExtensionError from "../components/popup/TimeExtensionError";
import ChatEnd from "../components/ChatEnd";

const ChatPopupContainer = () => {
    const {popupStyle} = useSelector(state => state.popup, shallowEqual);

    useEffect(() => {
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }, []);

    switch (popupStyle) {
        case 'chatEnd' :
        case 'chatLeft' :
            return (
                <ChatEndPopup popupStyle={popupStyle} />
            );
        case 'chatClose' :
            return (
                <ChatClosePopup />
            );
        case 'timeExtensionError' :
            return (
                <TimeExtensionError />
            )
        default :
            return (
                <AlertClosePopup popupStyle={popupStyle} />
            );
    }

    /*if (popupStyle === 'chatEnd') {
        return (
            <ChatEndPopup />
        );
    } else if (popupStyle === 'chatClose') {
        return (
            <ChatClosePopup />
        );
    } else if (popupStyle === 'timeExtensionError') {
        return (
            <TimeExtensionError />
        )
    } else {
        return (
            <AlertClosePopup />
        );
    }*/
};
export default ChatPopupContainer;
