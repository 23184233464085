import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    changeEditDone,
    changeInput,
    onSendButton,
    sendMessageTimeReset,
    typingStart,
    typingEnd,
    deleteChat,
    prevListAction,
    getPrevMessageList
} from '../modules/chat';
import { popupShow, popupClose } from "../modules/popup";
import ChatScreen from "../components/ChatScreen";
import ChatInput from "../components/ChatInput";
import ChatHeader from "../components/ChatHeader";
import { getTimestamp, bannedWordCheck, getByteLength } from '../lib/util';
import ChatTyping from "../components/ChatTyping";
import { openTimeExtensionPopup } from "../modules/timeExtension";

const ChatContainer = () => {
    const {input, editDone, chat, list, scroll, lastSendTime, lastSendTimeCnt, memberTyping} = useSelector(state => state.chat, shallowEqual);
    const {chatInfo} = useSelector((state) => state.connectChat, shallowEqual);
    const {userInfo} = useSelector((state) => state.connectUser, shallowEqual);

    const inputElem = useRef();

    const dispatch  = useDispatch();
    const onChangeEditDone = useCallback((editDone) => {
        dispatch(changeEditDone(editDone));
    }, [dispatch]);
    const onChangeInput = useCallback((input) => {
        dispatch(changeInput(input));
    }, [dispatch]);
    const onInsert = useCallback((text) => {
        dispatch(onSendButton(userInfo, chatInfo.channel.url, text));
    }, [dispatch]);
    const onPopupShow = useCallback((message) => {
        dispatch(popupShow(message));
    }, [dispatch]);
    const onPopupClose = useCallback((message) => {
        dispatch(popupClose());
    }, [dispatch]);
    const onSendMessageTimeReset = useCallback(() => {
        dispatch(sendMessageTimeReset());
    }, [dispatch]);
    const onPrevListAction = useCallback((status) => {
        dispatch(prevListAction(status));
    }, [dispatch]);
    const onPrevListMore = useCallback(() => {
        dispatch(getPrevMessageList(chatInfo.channel.url, userInfo, true));
    }, [dispatch]);
    const onGetUserCoin = useCallback(() => {
        dispatch(openTimeExtensionPopup(chatInfo.channel.url));
    }, [dispatch]);
    const onDeleteChat = useCallback((message) => {
        if (message && window.confirm('메세지를 삭제하시겠습니까?')) {
            dispatch(deleteChat(chatInfo.channel, message));
        }
    }, [dispatch]);
    const onSubmit = useCallback(e => {
        e.preventDefault();
        if (inputElem.current) {
            inputElem.current.focus();
        }
        if (lastSendTime !== getTimestamp()) {
            onSendMessageTimeReset();
        }
        if (lastSendTime === getTimestamp() && lastSendTimeCnt > 1) {
            onSendMessageTimeReset();
            onPopupShow({message: '너무 빠른 시간 내에 <br>메세지를 연속해서 <br>입력하실 수 없습니다.', style: 'alertClose'});
            return false;
        }
        // 한번에 쓸수 있는 글자수 300글자로 제한
        const count = getByteLength(input, 'length');
        if (count >= 500) {
            onPopupShow({message: '글자수 ( ' + count+ ' )<br>한번에 500 글자 (공백포함) 이상 <br>메세지를 입력하실 수 없습니다.', style: 'alertClose'});
            return false;
        }
        if (input.replace(/\s/gi, '') !== '') {
            const chekWord = bannedWordCheck(input);
            if (!chekWord) {
                onInsert(input);
            } else {
                onPopupShow({message: chekWord, style: 'alertClose'});
            }
        } else {
            //onPopupShow({message: '글자를 입력해 주세요.'});
            return false;
        }
        onChangeInput('');
        onChangeEditDone(false);

    }, [input, onInsert]);

    useEffect(() => {
        /**
         * 타이핑중인지
         */
        if (input) {
            dispatch(typingStart(chatInfo.channel.url));
        } else {
            dispatch(typingEnd(chatInfo.channel.url));
        }
    }, [input]);

    /**
     * 웹뷰에서 scroll 부모 영역의 높이값을 calc 100% - 100px 등으로 잡으면 스크롤이 안되는 문제발생
     * 해결방법 해당 부모창의 높이값을 고정시킨다.
     */
    const [chatScreenHeight, setChatScreenHeight] = useState(0);
    const handleResizeChatScreen = () => {
        // 하단 채팅입력창
        const windowHeight = window.innerHeight;
        const chatInputBox = 50;
        const userTypeHeader = (userInfo.type === 'caller') ? 172 : 148;
        const screenHeight = windowHeight - (chatInputBox + userTypeHeader);
        setChatScreenHeight(screenHeight);
    }

    useEffect(() => {
        handleResizeChatScreen();
        window.addEventListener('resize', handleResizeChatScreen);
        return () => {
            window.removeEventListener('resize', handleResizeChatScreen);
        };
    }, []);


    return (
        <div className="screen-wrap">
            <ChatHeader chatInfo={chatInfo} userInfo={userInfo} onGetUserCoin={onGetUserCoin} />
            <ChatScreen chatScreenHeight={chatScreenHeight} onScroll={scroll} userInfo={userInfo} chatList={chat} list={list} editDone={editDone} onPrevListAction={onPrevListAction} onPrevListMore={onPrevListMore} />
            <ChatTyping memberTyping={memberTyping} />
            <ChatInput input={input} onSubmit={onSubmit} onChangeInput={onChangeInput} inputElem={inputElem} />
        </div>
    );
};

export default ChatContainer;
