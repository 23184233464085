import qs from 'qs'
import artemisAxios from "../lib/artemisAxios";

export const checkOnline = (channel, ac_id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await artemisAxios({
                url: `/api/check/online`,
                method: 'post',
                data:  qs.stringify({
                    channel: channel,
                    ac_id: ac_id
                })
            });
            if (response.data.response === 'success') {
                resolve(response.data.isOnline);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    })
}

export const saveMessage = (message) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await artemisAxios({
                url: `/api/insert/chat`,
                method: 'post',
                data:  qs.stringify({
                    chatDate: message.chatDate,
                    channelUrl: message.channelUrl,
                    st_code: message.st_code,
                    caller: message.caller,
                    callee: message.callee,
                    messageId: message.messageId,
                    messageType: message.messageType,
                    channelType: message.channelType,
                    createdAt: message.createdAt,
                    sendingStatus: message.sendingStatus,
                    message: message.message,
                    _sender: (message._sender) ? JSON.stringify(message._sender) : '',
                    user: message.user
                })
            });
            if (response.data.response === 'success') {
                resolve(response);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    });
}