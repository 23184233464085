import React, {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {popupClose} from "../../modules/popup";
import Parser from 'html-react-parser';
import iconImg from '../../assets/img/ex_markx3.png';
import {chatCloseDisconnect} from "../../modules/connectChat";

const AlertClosePopup = ({ popupStyle }) => {
    const { popupTitle, popupMessage } = useSelector(state => state.popup, shallowEqual);

    const dispatch = useDispatch();
    const onPopupClose = useCallback(() => {
        dispatch(popupClose());
    }, [ dispatch ]);
    const onCloseChat = useCallback(() => {
        dispatch(chatCloseDisconnect());
    }, [dispatch]);

    return (
        <div className="popup-bg">
            <div className="popup-div">
                <div className="popup-top">
                    <div>
                        <h2 className="f-s16">{popupTitle}</h2>
                    </div>
                    <div>
                        <button type="button" className="popup-btn-close c-btn-close" onClick={onPopupClose}>닫기</button>
                    </div>
                </div>
                <div className="popup-center">
                    <div className="popup-img-div">
                        <div className="popup-img">
                            <img src={iconImg} alt=""/>
                        </div>
                        <p>{Parser(popupMessage)}</p>
                    </div>
                </div>
                <div className="popup-bottom">
                    <div className="popup-bottom-inner">
                        {popupStyle === 'missConnect'
                            ? <button type="button" className="popup-bottom-btn c-btn-close" onClick={onCloseChat}>확인</button>
                            : <button type="button" className="popup-bottom-btn c-btn-close" onClick={onPopupClose}>확인</button>
                        }

                    </div>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
};

export default AlertClosePopup;
