import dotenv from 'dotenv';
import SendBird from 'sendbird';

dotenv.config();

export const sbConnect = (userId, nickname, token) => {
    return new Promise((resolve, reject) => {
        if (!userId) {
            reject('UserID is required.');
            return;
        }
        if (!nickname) {
            reject('Nickname is required.');
            return;
        }
        if (!token) {
            reject('Token is required.');
            return;
        }
        const sb = new SendBird({ appId: process.env.REACT_APP_SB_ID });
        sb.connect(userId, token, (user, error) => {
            if (error) {
                reject('SendBird Login Failed.');
            } else {
                resolve(sbUpdateProfile(nickname));
            }
        });
    });
};

export const sbUpdateProfile = nickname => {
    return new Promise((resolve, reject) => {
        if (!nickname) {
            reject('Nickname is required.');
            return;
        }

        let sb = SendBird.getInstance();
        if (!sb) sb = new SendBird({ appId: process.env.REACT_APP_SB_ID });

        sb.updateCurrentUserInfo(nickname, null, (user, error) => {
            if (error) {
                reject('Update profile failed.');
            } else {
                resolve(user);
            }
        });
    });
};

export const sbDisconnect = () => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        if (sb) {
            sb.disconnect(() => {
                resolve(null);
            });
        } else {
            reject(null);
        }
    });
};