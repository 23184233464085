import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware, compose} from "redux";
import {Provider} from 'react-redux';
import rootReducer from "./modules";
import thunk from "redux-thunk";
import {BrowserRouter} from "react-router-dom";
import Router from './Route';
import {composeWithDevTools} from "redux-devtools-extension";
//import reportWebVitals from './reportWebVitals';

//const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const composeEnhancers = process.env.NODE_ENV !== "production" &&
(window).__REDUX_DEVTOOLS_EXTENSION__
    ? (window).__REDUX_DEVTOOLS_EXTENSION__ &&
(window).__REDUX_DEVTOOLS_EXTENSION__()
: compose;
const enhancer = compose(applyMiddleware(thunk), composeEnhancers);
const store = createStore(rootReducer, enhancer);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <Router/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
//reportWebVitals();
