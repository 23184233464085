import React, {useRef, useEffect} from 'react';
import {getDateTime} from "../lib/util";
import {useSelector} from "react-redux";

const AdminBox = ({chat}) => {
    return (
        <li className="d-flex-l p-rela admin">
            <div className="chat_bub">
                <div className="admin_basic">
                    <p><span className="bell">채팅 알림</span></p>
                </div>
                <p className="m6t">{chat.message}</p>
            </div>
        </li>
    )
};

const ChatBox = ({userType, chat}) => {
    const chatUserClass = (chat.user === userType) ? 'd-flex-l p-rela my-message' : 'd-flex-l p-rela other-message';

    return (
        <li className={chatUserClass}>
            <div className="chat_bub">
                <p className="chat_basic">
                    {chat.message}
                </p>
            </div>
            <div className="chat_text_div">
                <p className="chat_text">
                    {chat.readMessage ? '읽음' : '안읽음'}
                </p>
                <p className="chat_text bottom">
                    {getDateTime(chat.createdAt)}
                </p>
            </div>
        </li>
    );
};

const ChatScreen = ({chatScreenHeight, onScroll, userInfo, editDone, chatList, onPrevListAction, onPrevListMore}) => {
    const scrollRef         = useRef();
    const scrollWrap        = useRef();
    const prevListAction    = useSelector(state => state.chat.prevListAction);
    const prevList          = useSelector(state => state.chat.prevList);

    useEffect(() => {
        scrollRef.current.scrollIntoView(false);
    }, [editDone, onScroll]);

    const style = (chatScreenHeight === 0) ? {} : {height: chatScreenHeight + 'px'};

    if (chatList) {
        return (
            <div  className={"chat-wrap " + userInfo.type} ref={scrollWrap} style={style} onScroll={() => {
                if (scrollWrap.current.scrollTop ===0) {
                    if (!prevListAction && prevList) {
                        onPrevListMore();
                    }
                    onPrevListAction(true);
                }
            }}>
                <ul className="chat-bg" ref={scrollRef}>
                    {chatList.map(chat => (
                        (chat.user === 'admin')
                            ? <AdminBox chat={chat} key={chat.messageId} />
                            : <ChatBox userType={userInfo.type} chat={chat} key={chat.messageId} read={chat.read} />
                    ))}
                </ul>
            </div>
        );
    } else {
        return (
            <div  className={"chat-wrap " + userInfo.type} ref={scrollRef}>
                <div className="chat-bg">
                    <div className="d-flex-l p-rela admin">
                        <div className="chat_bub">
                            <div className="admin_basic">
                                <p><span className="bell">채팅 알림</span></p>
                            </div>
                            <p className="m6t">시스템 에러 잠시 후 다시 시도해 주세요.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ChatScreen;
