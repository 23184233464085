import hermesAxios from "../lib/hermesAxios";

/**
 * Hermes 에 채팅 가능한 회원인지 확인
 * @param user :: channel, ac_id, st_code
 * @returns {Promise<unknown>}
 */
export const getUserInfo = async (user = {}) => {
    return new Promise( async (resolve, reject) => {
        user.service_id = 'chat_service';
        const response = await hermesAxios({
            url: `/chat/login`,
            method: 'post',
            data: user
        });
        if (response.data.response === 'success') {
            resolve(response.data);
        } else {
            reject(response.data.error_msg);
        }
    });
}
/**
 * 회원이라면 Hermes 에 소켓 셋팅이 완료되었다는걸 알려준다.
 * 알림을 받은 Hermes 는 상담사에게 채팅 요청이 왔다는 메세지를 보낸다.
 */

export const sendStandby = (channelUrl) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await hermesAxios({
                url: `/chat/standby_caller`,
                method: 'post',
                data: {
                    channel: channelUrl
                }
            });
            if (response.data.response === 'success') {
                resolve(response.data.response);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    });
}
/**
 * 상담사라면 Hermes 에 처음 채팅 접속시 채팅 시작을 알려준다
 * 해당 전송이 이뤄지고나면 Hermes 에서 30초 무료라는 관리자 메세지와 함께 시작시간, 종료시간 등을 보내줌
 * @param channelUrl
 * @returns {Promise<unknown>}
 */
export const sendChatStart = (channelUrl) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await hermesAxios({
                url: `/chat/response`,
                method: 'post',
                data: {
                    channel: channelUrl
                }
            });
            if (response.data.response === 'success') {
                resolve(response.data.response);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    });
}
/**
 * 채팅방 종료
 * @param channelUrl
 * @returns {Promise<unknown>}
 */
export const deleteChannel =  (channelUrl) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await hermesAxios({
                url: `/chat/close`,
                method: 'post',
                data: {
                    channel: channelUrl
                }
            });
            if (response.data.response === 'success') {
                resolve(response.data.response);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    });
}
/**
 * 보유 코인 확인
 * @param channelUrl
 * @returns {Promise<unknown>} remain_coin, coin_price
 */
export const getUserCoin = (channelUrl) => {
    return new Promise (async(resolve, reject) => {
        try {
            const response = await hermesAxios({
                url: `/chat/getamount`,
                method: 'post',
                data: {
                    channel: channelUrl
                }
            });
            if (response.data.response === 'success') {
                resolve(response.data);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    });
}
/**
 * 시간연장
 * @param channelUrl
 * @param duration
 * @param useCoin
 * @returns {Promise<unknown>}
 */
export const setAddTime = (channelUrl, userId, duration, useCoin) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await hermesAxios({
                url: `/chat/addamount`,
                method: 'post',
                data: {
                    channel: channelUrl,
                    ac_id: userId,
                    add_duration: duration,
                    useCoin: useCoin,
                }
            });
            if (response.data.response === 'success') {
                resolve(response.data);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    });
}

export const getChatInfo = (channelUrl) => {
    return new Promise (async (resolve, reject) => {
        try {
            const response = await hermesAxios({
                url: `/chat/chatinfo`,
                method: 'post',
                data: {
                    channel: channelUrl
                }
            });
            if (response.data.response === 'success') {
                resolve(response.data);
            } else {
                reject(response.data.error_msg);
            }
        } catch (e) {
            reject(e.message);
        }
    });
}



