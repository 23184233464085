import SendBird from 'sendbird';
import {sbGetGroupChannel} from "./groupChannel";

/**
 * 타이핑 시작
 * @param channelUrl
 * @returns {Promise<unknown>}
 */
export const sbTypingStart = channelUrl => {
    return new Promise((resolve, reject) => {
        sbGetGroupChannel(channelUrl)
            .then(channel => {
                channel.startTyping();
                resolve(channel);
            })
            .catch(error => reject(error));
    });
};
/**
 * 타이핑 종료
 * @param channelUrl
 * @returns {Promise<unknown>}
 */
export const sbTypingEnd = channelUrl => {
    return new Promise((resolve, reject) => {
        sbGetGroupChannel(channelUrl)
            .then(channel => {
                channel.endTyping();
                resolve(channel);
            })
            .catch(error => {
                reject(error);
            });
    });
};
/**
 * 메세지 보내기
 * @param channel
 * @param textMessage
 * @param callback
 * @returns {*}
 */
export const sbSendTextMessage = (channel, textMessage, callback) => {
    if (channel.isGroupChannel()) {
        // 타이핑 종료
        channel.endTyping();
    }
    return channel.sendUserMessage(textMessage, (message, error) => {
        callback(message, error);
    });
};

export const sbCreatePreviousMessageListQuery = (channelUrl) => {
    return new Promise((resolve, reject) => {
        sbGetGroupChannel(channelUrl)
            .then(channel => resolve(channel.createPreviousMessageListQuery()))
            .catch(error => reject(error));
    });
};
/**
 * 메세지 목록 가져오기
 * @param previousMessageListQuery
 * @returns {Promise<unknown>}
 */
export const sbGetMessageList = previousMessageListQuery => {
    const limit = 30;
    const reverse = true;
    return new Promise((resolve, reject) => {
        previousMessageListQuery.load(limit, reverse, (messages, error) => {
            if (error) {
                reject(error);
            } else {
                resolve(messages);
            }
        });
    });
};

export const sbGetMessagesByMessageId = (channel, messageId) => {
    const sb = SendBird.getInstance();
    const params = new sb.MessageListParams();
    params.isInclusive = false;
    params.prevResultSize = 30;
    params.shouldReverse = true;
    params.reverse = true;

    return new Promise((resolve, reject) => {
        channel.getMessagesByMessageId(messageId, params,(messages, error) => {
            if (error) {
                reject(error);
            } else {
                resolve(messages);
            }
        });
    });
}
/**
 * 메세지 삭제
 * @param channel
 * @param message
 * @returns {Promise<unknown>}
 */
export const sbDeleteMessage = (channel, message ) => {
    return new Promise ((resolve, reject) => {
        if (channel) {
            channel.deleteMessage(message, (response, error) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(response);
                }
            });
        } else {
            resolve('채널이 없습니다.');
        }
    })
}