import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from "react-redux";
import loadingImg from  '../assets/img/loading.gif';
import '../assets/css/connect.css';
import {channelDeleteSend, connectStatus} from "../modules/connectChat";

const Caller = ({userInfo}) => {
    const connectWrapStyle = {
        height: "500px",
    };
    const connectStyle = {
        height: "100%",
    }
    return (
        <div className="coin-chat-wrap" style={connectWrapStyle}>
            <div className="store-info">
                <div className="store-info-inner p50t">
                    <p className="store-name">{userInfo.callee.ac_nick}</p>
                    <div className="store-img m10t">
                        <img src={userInfo.callee.icon_url} alt={userInfo.callee.ac_nick} />
                    </div>
                </div>
            </div>
            <div className="store-reserv" style={connectStyle}>
                <div className="loding">
                    <div className="loding-bar">
                        <img src={loadingImg} alt="roding" />
                    </div>
                    <p className="loding-text">선택하신 스토어에 연결 중입니다.</p>
                    <p className="loding-text">잠시만 기다려 주세요.</p>
                </div>
            </div>
        </div>
    );
};

const Callee = () => {
    return (
        <div className="coin-connect-wrap">
            <div className="loding">
                <div className="loding-bar">
                    <img src={loadingImg} alt="roding" />
                </div>
                <p className="loding-text">내담자와 연결 중입니다.</p>
            </div>
        </div>
    );
};

const Connect = ({userInfo, chatConnect, chatStart}) => {
    const dispatch = useDispatch();
    const [displayClass, setDisplayClass] = useState('show');

    useEffect(() => {
        if (chatConnect && chatStart) {
            setDisplayClass('none');
            setTimeout(() => {
                dispatch(connectStatus(false));
            }, 1000);
        }
    }, [chatConnect, chatStart]);

    const onChannelDeleteSend = useCallback(() => {
        dispatch(channelDeleteSend(userInfo.channel));
    }, [dispatch]);

    return (
        <>
            <div className={"connect-wrap " + displayClass}>
                <header id="header" className="chat-header">
                    <h1 className="header-title">코인 채팅 상담</h1>
                    {userInfo.type === 'caller' && userInfo.startTime === "" ? <button className="popup-btn-close" onClick={onChannelDeleteSend}>종료</button> : ''}
                </header>
                {userInfo.type === 'callee' ? <Callee /> : <Caller userInfo={userInfo} />}
            </div>
        </>
    )
};

export default Connect;