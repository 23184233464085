import React, {useEffect} from "react";
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {getUserInfo} from "../modules/connectUser";
import Loading from "../components/Loading";
import ChatEnd from "../components/ChatEnd";

const ConnectUserContainer = ({token}) => {
    const {userLoading, userConnect, userErrorMsg} = useSelector((state) => state.connectUser, shallowEqual);

    const dispatch = useDispatch();
    useEffect(() => {
        /**
         * token => 현재는 callee, caller
         * jwt 토큰등을 이용해서 데이터 가져와서 HERMES와 통신한다.
         */
        dispatch(getUserInfo(token));
    }, []);

    if (userLoading) return <Loading onLoading={userLoading}/>;
    if (!userConnect) return <ChatEnd message={userErrorMsg} />;
    return (
        <></>
    );
}

export default ConnectUserContainer;