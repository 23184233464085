import {createAction, handleActions} from 'redux-actions';
import * as sendbird from '../api/sendbird';
import * as hermes from '../api/hermes';
import {popupClose, popupShow} from "./popup";

const LOADING                   = 'connectChat/LOADING';
const LOADING_END               = 'connectChat/LOADING_END';
const SOCKET_ON                 = 'connectChat/SOCKET_ON';
const CONNECT_SHOW              = 'connectChat/CONNECT_SHOW';
const CHAT_LOGIN                = 'connectChat/CHAT_LOGIN';
const CHAT_LOGIN_SUCCESS        = 'connectChat/CHAT_LOGIN_SUCCESS';
const CHAT_LOGIN_ERROR          = 'connectChat/CHAT_LOGIN_ERROR';
const CHAT_CHANNEL_CHECK        = 'connectChat/CHAT_CHANNEL_CHECK';
const CHAT_CREATE               = 'connectChat/CHAT_CREATE';
const CHAT_CREATE_SUCCESS       = 'connectChat/CHAT_CREATE_SUCCESS';
const CHAT_CREATE_ERROR         = 'connectChat/CHAT_CREATE_ERROR';
const CHAT_CONNECT              = 'connectChat/CHAT_CONNECT';
const CHAT_CONNECT_SUCCESS      = 'connectChat/CHAT_CONNECT_SUCCESS';
const CHAT_CONNECT_ERROR        = 'connectChat/CHAT_CONNECT_ERROR';
const CHAT_DELETE_SEND          = 'connectChat/CHAT_DELETE_SEND';
const CHAT_DELETE_SEND_SUCCESS  = 'connectChat/CHAT_DELETE_SEND_SUCCESS';
const CHAT_DELETE_SEND_ERROR    = 'connectChat/CHAT_DELETE_SEND_ERROR';
const CHAT_CLOSE                = 'connectChat/CHAT_CLOSE';
const CHAT_START_TIME           = 'chat/CHAT_START_TIME';
const CHAT_END_TIME             = 'chat/CHAT_END_TIME';
const CHAT_DURATION             = 'chat/CHAT_DURATION';
const CHAT_USE_COIN             = 'chat/CHAT_USE_COIN';
const CHAT_START                = 'chat/CHAT_START';

export const connectStatus = createAction(CONNECT_SHOW, status=>status);
/**
 * 샌드버드 로그인
 * @param ac_id
 * @param ac_nick
 * @param token
 * @returns {function(*): Promise<void>}
 */
export const onChatLogin = (ac_id, ac_nick, token) => async dispatch => {
    dispatch({type: CHAT_LOGIN});
    try {
        const response = await sendbird.sbConnect(ac_id, ac_nick, token);
        dispatch({type: CHAT_LOGIN_SUCCESS, sb: response});
    } catch(e) {
        dispatch({type: CHAT_LOGIN_ERROR, chatErrorMsg: e});
    }
};
/**
 * caller
 * 샌드버드 그룹채팅 생성
 * 생성된 채팅방이 있다면 참여 없다면 생성
 * @param callerId
 * @param calleeId
 * @param channelKey
 * @returns {function(*): Promise<boolean|undefined>}
 */
export const onCreatChat = (callerId, calleeId, channelKey) => async dispatch => {
    // 생성된 채팅방이 있는지 검사
    dispatch({type: CHAT_CHANNEL_CHECK});
    try {
        // 채팅방이 있는지 확인
        const response = await sendbird.sbGetGroupChannel(channelKey);
        dispatch({type: CHAT_CREATE});
        dispatch({type: CHAT_CREATE_SUCCESS, channel: response});
        //dispatch({type: CHAT_CONNECT_SUCCESS});
        if (response.members.find(user=>user.userId === calleeId)) {
            dispatch({type: CHAT_CONNECT_SUCCESS});
            dispatch(setChatStart());
        }
    } catch (e) {
        if (e.code === 400201) {
            // 채팅방이 없으니 생성한다.
            dispatch({type: CHAT_CREATE});
            // 채팅방 (그룹채널 생성)
            try {
                const response = await sendbird.sbCreateGroupChannelParams(callerId, channelKey);
                dispatch({type: CHAT_CREATE_SUCCESS, channel: response});
            } catch (e) {
                dispatch({type: CHAT_CREATE_ERROR, chatErrorMsg: e.message});
                return false;
            }
        } else {
            dispatch({type: CHAT_CREATE_ERROR, chatErrorMsg: e.message});
        }
    }
};
/**
 *  callee
 *  샌드버드 그룹채팅 참가
 * @param channelUrl
 * @returns {function(*, *): Promise<boolean|undefined>}
 */
export const onJoinChat = (channelUrl) => async (dispatch) => {
    let channel = null;
    dispatch({type: CHAT_CREATE});
    try {
        const response = await sendbird.sbGetGroupChannel(channelUrl);
        channel = response;
        dispatch({type: CHAT_CREATE_SUCCESS, channel: response});
    } catch (e) {
        dispatch({type: CHAT_CREATE_ERROR, chatErrorMsg: e.message});
        return false;
    }
    dispatch({type: CHAT_CONNECT});
    try {
        await sendbird.sbJoinChannel(channel);
        dispatch({type: CHAT_CONNECT_SUCCESS});
    } catch(e) {
        dispatch({type: CHAT_CONNECT_ERROR, chatErrorMsg: e.message});
        return false;
    }
}
/**
 * 시작시간이 없다면 Hermes 에 시작알림을 보낸다.
 * @returns {function(*, *): Promise<boolean|undefined>}
 */
export const sendChatStart = () => async (dispatch, getState) => {
    const chatInfo = getState().connectChat.chatInfo;
    if (chatInfo.startTime === null && chatInfo.endTime === null) {
        // Hermes 에 상담 시작을 알린다.
        try {
            await hermes.sendChatStart(chatInfo.channel.url);
            dispatch(setChatStart());
        } catch(e) {
            // 상담시작 알림 실패하면 상담연결 종료 처리
            dispatch({type: CHAT_CLOSE});
            return false;
        }
    } else {
        dispatch(setChatStart());
    }
}
/**
 * 채팅 강제 종료
 * @param channelUrl
 * @returns {function(*): Promise<void>}
 */
export const channelDeleteSend = (channelUrl) => async dispatch => {
    dispatch({type: LOADING});
    dispatch({type: CHAT_DELETE_SEND});
    try {
        await hermes.deleteChannel(channelUrl)
        dispatch({type: CHAT_DELETE_SEND_SUCCESS});
    } catch (e) {
        dispatch (popupShow({message: e}));
        dispatch({type: CHAT_DELETE_SEND_ERROR});
        dispatch({type: LOADING_END});
    }
}
/**
 * 서버에서 채팅방 삭제시 채팅방 종료 팝업을 보여줌
 * 종료 Handler 실행시 팝업관련 전부 리셋
 * @param channelUrl
 * @returns {function(*): Promise<void>}
 */
export const channelDelete = (channelUrl) => async dispatch => {
    try {
        // hermes api 에서 채팅 정보를 가져와야함
        const response = await hermes.getChatInfo(channelUrl);
        dispatch (popupClose());
        dispatch({type: LOADING_END});
        if (response.connect && response.connect === 'true' && response.time && response.time !== '') {
            const popData = {
                style: 'chatEnd',
                title: '안내',
                message: '',
                time: response.duration,
                useCoin: response.useCoin
            };
            dispatch (popupShow(popData));
        } else if (response.connect && response.connect === 'false') {
            // 상담사 미연결
            const popData = {
                style: 'missConnect',
                title: '채팅상담 미연결 안내',
                message: '응답시간이 초과하여 <br>채팅상담이 연결되지 않았습니다. <br>다시 시도해주세요.',
            };
            dispatch (popupShow(popData));
        } else {
            // 데이터가 없다면 바로 종료
            dispatch(chatCloseDisconnect());
        }
    } catch (e) {
        // 오류가 나도 종료
        dispatch(chatCloseDisconnect());
    }
}
export const channelLeftPopup = (channelUrl, style = 'chatEnd') => async dispatch => {
    dispatch({type: LOADING});
    try {
        // hermes api 에서 채팅 정보를 가져와야함
        const response = await hermes.getChatInfo(channelUrl);
        const popData = {
            style: style,
            title: '안내',
            message: '',
            duration: response.duration,
            useCoin: response.useCoin
        };
        dispatch({type: LOADING_END});
        dispatch (popupShow(popData));
    } catch (e) {
        dispatch(popupShow({
            title: '안내',
            message: e.error_msg
        }));
    }
};
/**
 * channelDelete 작업 후 확인 버튼을 누르면 채팅창 종료됨.
 * @returns {function(*): Promise<void>}
 */
export const chatCloseDisconnect = () => async dispatch => {
    try {
        // 로그아웃 시킴
        await sendbird.sbDisconnect();
    } catch (e) {
        // 로그아웃이 안되도 채팅방은 삭제되었으니 계속 진행
    }
    dispatch({type: CHAT_CLOSE});
}
export const setSocketOn = createAction(SOCKET_ON);
export const setStartTime = createAction(CHAT_START_TIME, startTime => startTime);
export const setEndTime = createAction(CHAT_END_TIME, endTime => endTime);
export const setDuration = createAction(CHAT_DURATION, duration => duration);
export const setUseCoin = createAction(CHAT_USE_COIN, useCoin => useCoin);
export const setChatStart = createAction(CHAT_START);
/***
 * chatInfo.chatConnect => caller : 채팅 생성 후 callee 참여 핸들러가 작동하면 업데이트 시킨다. callee : 로그인 후 채팅방에 참여하면서 업데이트 시킨다.
 * @type {{chatError: boolean, chatLoading: boolean, chatLogin: boolean, chatErrorMsg: null, chatCreat: boolean, chatConnect: boolean,
 * chatInfo: {channel: null, chatConnect: boolean, startTime: null, endTime: null, chatUser: [], sb: null}}}
 */
const initialState = {
    connectShow: false,
    chatLogin: false,
    chatLoading: false,
    chatCreat: false,
    chatCreatSuccess: false,
    socketOn: false,
    chatConnect: false,
    chatStart: false,
    chatInfo: {
        sb: null,
        channel: null,
        startTime: null,
        endTime: null,
        useCoin: 0,
        duration: 0,
        time: null,
    },
    chatError: false,
    chatErrorMsg: null,
    chatClose: false,
};

const connectChat = handleActions(
    {
        [LOADING]: (state) => ({
           ...state,
           chatLoading: true,
        }),
        [LOADING_END]: (state) => ({
            ...state,
            chatLoading: false,
        }),
        [SOCKET_ON]: (state) => ({
            ...state,
            socketOn: true
        }),
        [CONNECT_SHOW]: (state, {payload: status}) => ({
            ...state,
            connectShow: status
        }),
        [CHAT_LOGIN]: (state) => ({
            ...state,
            chatLoading: true,
        }),
        [CHAT_LOGIN_SUCCESS]: (state, action) => ({
            ...state,
            chatLogin: true,
            chatLoading: false,
            chatInfo:{
                ...state.chatInfo,
                sb: action.sb,
            },
        }),
        [CHAT_LOGIN_ERROR]: (state, action) => ({
            ...state,
            chatLogin: false,
            chatLoading: false,
            chatError: true,
            chatErrorMsg: action.chatErrorMsg
        }),
        [CHAT_CHANNEL_CHECK]: (state) => ({
            ...state,
        }),
        [CHAT_CREATE]: (state, action) => ({
            ...state,
            chatCreat: true,
        }),
        [CHAT_CREATE_SUCCESS]: (state, action) => ({
            ...state,
            chatCreatSuccess: true,
            chatInfo: {
                ...state.chatInfo,
                channel: action.channel,
            }
        }),
        [CHAT_CREATE_ERROR]: (state, action) => ({
            ...state,
            chatCreat: false,
            chatError: true,
            chatErrorMsg: action.chatErrorMsg
        }),
        [CHAT_CONNECT]: (state) => ({
            ...state,
        }),
        [CHAT_CONNECT_SUCCESS]: (state, action) => ({
            ...state,
            chatConnect: true,
            chatInfo: {
                ...state.chatInfo,
            }
        }),
        [CHAT_CONNECT_ERROR]: (state, action) => ({
            ...state,
            chatError: true,
            chatErrorMsg: action.chatErrorMsg
        }),
        [CHAT_DURATION]: (state, {payload: duration}) => ({
            ...state,
            chatInfo: {
                ...state.chatInfo,
                duration: duration,
            }
        }),
        [CHAT_USE_COIN]: (state, {payload: useCoin}) => ({
            ...state,
            chatInfo: {
                ...state.chatInfo,
                useCoin: useCoin,
            }
        }),
        [CHAT_START_TIME]: (state, {payload: startTime}) => ({
            ...state,
            chatInfo: {
                ...state.chatInfo,
                startTime: startTime
            }
        }),
        [CHAT_END_TIME]: (state, {payload: endTime}) => ({
            ...state,
            chatInfo: {
                ...state.chatInfo,
                endTime: endTime
            }
        }),
        [CHAT_START]: (state) => ({
            ...state,
            chatStart: true,
        }),
        [CHAT_DELETE_SEND]: (state) => ({
            ...state,
        }),
        [CHAT_DELETE_SEND_SUCCESS]: (state) => ({
            ...state,
        }),
        [CHAT_DELETE_SEND_ERROR]: (state) => ({
            ...state,
        }),
        [CHAT_CLOSE]: (state) => ({
            ...state,
            chatConnect: false,
            chatClose: true,
        })
    },
    initialState
);

export default connectChat;
