import React, {useCallback, useState} from 'react';
import {getDurationToCoin, numberWithCommas} from "../lib/util";

const TimeExtension = ({ it_coin_price, setDurationTime, myCoin, onPopupClose, onTimeExtension }) => {
    const [duration, setDuration] = useState(0);
    const [useCoin , setUseCoin] = useState(0);
    const onSelect = useCallback((e) => {
        setDuration(e.target.value);
        const useCoin = getDurationToCoin(parseInt(e.target.value), it_coin_price);
        setUseCoin(useCoin);
    }, []);
    const submit = useCallback(() => {
        if (duration === 0 || useCoin === 0) {
            return false;
        }
        const data = {
            duration: duration,
            useCoin: useCoin
        }
        onTimeExtension(data);
    }, [duration, useCoin])

    return (
        <div className="popup-bg popup-time-extension">
            <div className="popup-div">
                <div className="popup-top">
                    <div>
                        <h2 className="f-s16">상담시간 연장</h2>
                    </div>
                    <div>
                        <button type="button" className="popup-btn-close c-btn-close" onClick={onPopupClose}>닫기</button>
                    </div>
                </div>
                <div className="popup-center">
                    <div className="sangdam-inner-time">
                        <p className="f-bold f-s16 m10b">상담시간</p>
                        <div className="d-flex radio-box">
                            {setDurationTime.map((time, idx) => (
                                <label key={time.key} htmlFor={time.key} className="radio-label">
                                    <input type="radio" name="test" id={time.key} value={time.duration} onChange={onSelect}/>
                                    <span className="">
                                        <span className="radio-span-inner"></span>
                                    </span>
                                    {time.text}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="sangdam-inner-pay">
                        <p className="f-bold f-s16 m10b">결제하기</p>
                        <p className="f-s12 m10b">선택하신 상담시간에 따른 코인 결제내역 입니다.</p>
                        <div className="d-flex m10b">
                            <p className="s-center">· 나의 코인</p>
                            <div className="sangdam-inner-coin">
                                <p className="en-breck">{numberWithCommas(myCoin)}</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            <p className="s-center">· 차감 코인</p>
                            <div className="sangdam-inner-coin">
                                <p className="en-breck"><span className="color_fa233b-b">{numberWithCommas(useCoin)}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-bottom">
                    <div className="popup-bottom-inner">
                        <button type="buttonn" className="popup-bottom-btn c-btn-close m8r cancel" onClick={onPopupClose}>취소</button>
                        <button type="button" className="popup-bottom-btn c-btn-close" onClick={submit}>연장</button>
                    </div>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
};

export default TimeExtension;