import SendBird from 'sendbird';

/**
 * 그룹채널 가져오기
 * @param channelUrl
 * @returns {Promise<unknown>}
 */
export const sbGetGroupChannel = channelUrl => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
            if (error) {
                reject(error);
            } else {
                resolve(channel);
            }
        });
    });
};
/**
 * 그룹채널 나각기
 * @param channelUrl
 * @returns {Promise<unknown>}
 */
export const sbLeaveGroupChannel = channelUrl => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        sbGetGroupChannel(channelUrl)
            .then(channel => {
                channel.leave((response, error) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(response);
                    }
                });
            })
            .catch(error => reject(error));
    });
};

export const sbCreateUserListQuery = () => {
    const sb = SendBird.getInstance();
    return sb.createApplicationUserListQuery();
};

const getGroupChannelParams = () => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        return new sb.GroupChannelParams();
    });
};
/**
 * 샌드버드 채널 생성
 * Hermes 에서 채널아이디 생성해서 보내주기때문에 커스텀 생성방식으로 그룹채널 생성
 * @param callerId
 * @param channelKey
 * @returns {Promise<unknown>}
 */
export const sbCreateGroupChannelParams = (callerId, channelKey) => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        let params = new sb.GroupChannelParams();
        params.isPublic = true;
        params.isEphemeral = false;
        params.isDistinct = false;
        params.isSuper = false;
        params.addUserIds([callerId]);
        params.operatorUserIds = [callerId];
        params.channelUrl = channelKey;

        sb.GroupChannel.createChannel(params, function(channel, error) {
            if (error) {
                reject(error);
            } else {
                resolve(channel);
            }
        });
    });
};
/**
 * 상담사 전용
 * 연결할 채팅방을 가져와서 참여
 * @param channel
 * @returns {Promise<unknown>}
 */
export const sbJoinChannel = (channel) => {
    return new Promise((resolve, reject) => {
        channel.join((response, error) => {
            if (error) {
                reject(error);
            } else {
                resolve(response);
            }
        });
    });
}