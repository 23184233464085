import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch} from "react-redux";
import {getRemainTime, setKorTime} from '../lib/util';
import {popupShow} from "../modules/popup";
import {channelLeftPopup} from "../modules/connectChat";

const CallerInfo = ({userInfo}) => {
    return (
        <>
            <div className="small-profile-title">
                <p className="item-name f-bold">{userInfo.caller.ac_nick}</p>
            </div>
        </>
    );
};

const CalleeInfo = ({userInfo}) => {
    return (
        <>
            <div className="small-profile">
                <img src={userInfo.callee.icon_url} alt="상담사사진"/>
            </div>
            <div className="small-profile-title">
                <div className="d-flex">
                    <p className="item-name text-tag">{userInfo.callee.it_category}</p>
                </div>
                <p className="item-name f-bold">{userInfo.callee.ac_nick}</p>
            </div>
        </>
    );
}

let timer = null;

const ChatHeader = ({ chatInfo, userInfo, onGetUserCoin }) => {
    const [remainTime, setRemainTime] = useState('00 : 00 : 00');
    useEffect(() => {
        // 진입전에 타이머가 있다면 리셋
        if (timer) {
            clearInterval(timer);
            timer = null;
        }
        if (chatInfo.endTime !== null) {
            // ie 11 맞추기 위해선 데이터값 변경해야함
            const endTime = new Date((chatInfo.endTime).replace(/[.-]/gi, "/"));
            if (timer === null) {
                timer = setInterval(() => {
                    setRemainTime(getRemainTime(endTime));
                }, 1000);
            }
        }
        return () => {
            // 타이머 삭제
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
        }
    }, [chatInfo.endTime]);

    const dispatch = useDispatch();
    const onPopupShow = useCallback(() => {
        if (userInfo.type === 'caller') {
            // 회원은 채팅방 나가기
            dispatch(channelLeftPopup(chatInfo.channel.url, 'chatLeft'));
        } else {
            // 상담사는 채팅방 종료
            dispatch(popupShow({style: 'chatClose', title: '상담종료', message: ''}));
        }
    }, [dispatch]);

    return (
        <>
            <header id="header" className="chat-header">
                <h1 className="header-title">코인 채팅 상담</h1>
                <p className="chat-end-text">상담종료</p>
                <button className="popup-btn-close" onClick={onPopupShow}>종료</button>
            </header>
            <div className="d-flex-l chat-info">
                {(userInfo.type === 'callee') ? <CallerInfo userInfo={userInfo} /> : <CalleeInfo userInfo={userInfo} />}
            </div>
            <div className="d-flex chat-time-info">
                <div className="d-flex">
                    <p className="sangdam-time">상담시간</p>
                    <p className="f-bold f-s11 time-limit">{setKorTime(chatInfo.duration)}</p>
                </div>
                <div className="d-flex">
                    <p className="sangdam-time">남은시간</p>
                    <p className="f-s11 time-limit">{remainTime}</p>
                </div>
                {userInfo.type === 'caller' ? <div className="d-flex">
                    <button type="button" className="yeonjang f-bold" onClick={onGetUserCoin}><span className="bg-arrow-w">상담시간 연장하기</span></button>
                </div> : ''}
            </div>
        </>
    );
};

export default ChatHeader;