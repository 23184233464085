import React from 'react';
import Parser from 'html-react-parser';
import iconImg from '../../assets/img/ex_markx3.png';

const AlertPopup = ({message}) => {
    return (
        <div className="popup-bg">
            <div className="popup-div">
                <div className="popup-top">
                    <div>
                        <h2 className="f-s16">알림</h2>
                    </div>
                </div>
                <div className="popup-center">
                    <div className="popup-img-div chat-end-p">
                        <div className="popup-img">
                            <img src={iconImg} alt={message} />
                        </div>
                        <p>
                            {message ? Parser(message) : Parser('채팅상담 연결이 <br />종료되었습니다.')}
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
};

export default AlertPopup;