import crypto from 'crypto';

const secureKey = process.env.REACT_APP_SECURE_KEY;
const secureIv  = process.env.REACT_APP_SECURE_IV;

export const decrypt = (str) => {
    const decipher = crypto.createDecipheriv('aes-256-ctr', secureKey, secureIv);
    let dec = decipher.update(str,'hex','utf8')
    dec += decipher.final('utf8');
    return dec;
}