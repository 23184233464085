export const addZero = (num) => {
    return String(num).replace(/^([0-9]{1})$/ig, '0$1');
}

export const getByteLength = (str = '', mode= 'byte') => {
    let byte = 0;
    if (mode === 'byte') {
        for (let i = 0, length = str.length; i < length; i++) {
            let code = str.charCodeAt(i);
            if (code > 127) {
                byte += 2;
            } else if (code > 64 && code < 91) {
                byte += 2;
            } else {
                byte += 1;
            }
        }
    } else {
        byte = str.length;
    }

    return byte;
}

export const getDateTime = (timestamp = '') => {
    const dateObj = (timestamp) ? new Date(timestamp) : new Date();
    //return dateObj.toLocaleTimeString();
    return dateObj.toLocaleTimeString().slice(0, -3);
};

export const setKorTime = (duration = 0, type = 'min') => {
    if (type === 'min') {
        const min = Math.floor(duration / 60)
        const sec = duration - min * 60
        return addZero(min) + '분' + addZero(sec) + '초'
    } else {
        const hour = Math.floor(duration / 3600)
        const min = Math.floor((duration - hour * 3600) / 60)
        const sec = duration - hour * 3600 - min * 60
        return addZero(hour) + '시' + addZero(min) + '분' + addZero(sec) + '초'
    }
}

export const getRemainTime = (endTime) => {
    const currDay = new Date();
    let diff = endTime - currDay;
    const diffDays = Math.floor((endTime.getTime() - currDay.getTime()) / (1000 * 60 * 60 * 24));
    diff -= diffDays * (1000 * 60 * 60 * 24);
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    diff -= diffHours * (1000 * 60 * 60);
    const diffMin = Math.floor(diff / (1000 * 60));
    diff -= diffMin * (1000 * 60);
    const diffSec = Math.floor(diff / 1000);

    let textDiffHours = addZero(diffHours);
    let textDiffMin = addZero(diffMin);
    let textDiffSec = addZero(diffSec);

   // console.log(textDiffHours, diffHours);
    if (diffDays < 0) {
        return '00 : 00 : 00';
    } else {
        return textDiffHours + ' : ' + textDiffMin + ' : ' + textDiffSec;
    }
}

export const getTimestamp = () => {
    return Math.floor(+ new Date() / 1000);
}

const bannedWord = [
    '자지','씨부럴','개자지','개보지','개보대','종간나새끼','씨붕알','붕알','fuck','dick', 'penis', 'bitch', 'sex','asshole','좆','개새','씨발','씹',
    '창녀','앰창','엄창','개년','개걸레','쎅스','섹스','후장','사까시','오랄','정액','보짓','아가리','대가리','갈빡','귀두','부랄','똥꼬',
    '찐따','썅','쌍년','시발아','시발년','시발놈','꼬추','빠가','애미','애비','씨바','클리토리스','18아','18놈','18새끼','18뇬','18노',
    '18것','18넘','개년','개놈','개뇬','개새','개색끼','개세끼','개세이','개쉐이','개쉑','개쉽','개시키','개자식','눈깔','병쉰','병신',
    '뻐큐','뻑큐','뽁큐','삐리넷','새꺄','쉬발','쉬밸','쉬팔','쉽알','아가리','조까','조빠네','존나','존니','쥐랄','지롤', '오나홀', '육변기'
];
export const bannedWordCheck = (message) => {
    for (let i=0; i<bannedWord.length; i++){
        let word = bannedWord[i].replace(/(^\s*)|(\s*$)/g, "");
        let reg = new RegExp(word, 'gi');
        if(reg.test(message.replace(/(\s*)/g, ''))){
            return '댓글에 금지단어 "'+word+'" 이(가) <br>포함되어 있습니다.';
        }
    }
    return false;
};

export const getDurationToCoin = (duration = 0, price = 0) => {
    return parseInt(price) * Math.floor(parseInt(duration) / 30);
}

export const numberWithCommas = (number= 0) =>{
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const sleep = (n) => new Promise(resolve => setTimeout(resolve, n));