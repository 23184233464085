import {combineReducers} from "redux";
import connectUser from "./connectUser";
import connectChat from "./connectChat";
import chat from "./chat";
import popup from './popup';
import timeExtension from "./timeExtension";

const rootReducer = combineReducers({
    connectUser,
    connectChat,
    chat,
    popup,
    timeExtension,
});

export default rootReducer;