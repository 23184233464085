import React, {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {channelDeleteSend} from "../../modules/connectChat";
import {popupClose} from "../../modules/popup";

const ChatClosePopup = () => {
    const {popupTitle} = useSelector(state => state.popup, shallowEqual);
    const userInfo = useSelector(state => state.connectUser.userInfo, shallowEqual);

    const dispatch = useDispatch();
    const onChannelDeleteSend = useCallback(() => {
        dispatch(channelDeleteSend(userInfo.channel));
    }, [dispatch]);
    const onPopupClose = useCallback(() => {
        dispatch(popupClose());
    }, [dispatch]);

    return (
        <div className="popup-bg">
            <div className="popup-div">
                <div className="popup-top">
                    <div>
                        <h2 className="f-s16">{popupTitle}</h2>
                    </div>
                    <div>
                        <button type="button" className="popup-btn-close c-btn-close" onClick={onPopupClose}>닫기</button>
                    </div>
                </div>
                <div className="popup-center">
                    <div className="popup-table m24t">
                        <p className="m11b"><span className="f-s18 color-fa233b">상담종료</span></p>
                        <p className="m21b">
                            {userInfo.type === 'caller'
                                ? `[ ${userInfo.callee.ac_nick} ] 스토어와`
                                : `[ ${userInfo.caller.ac_nick} ] 님과` }
                            <br />상담을 종료합니다.
                            <br />종료하는 경우 상담 시간이 남아있더라도<br />재입장이 불가합니다.
                        </p>
                    </div>
                </div>
                <div className="popup-bottom">
                    <div className="popup-bottom-inner border-t-0">
                        <button type="button" className="popup-bottom-btn c-btn-close m8r cancel" onClick={onPopupClose}>취소</button>
                        <button type="button" className="popup-bottom-btn c-btn-close m8r" onClick={onChannelDeleteSend}>확인</button>
                    </div>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
};

export default ChatClosePopup;
