import React, {useEffect} from "react";
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import Connect from "../components/Connect";
import {onChatLogin, onCreatChat, onJoinChat, connectStatus, sendChatStart} from "../modules/connectChat";
import Loading from "../components/Loading";
import {registerGroupChannelHandler} from "../lib/chatHandler";
import {sbMarkAsRead, getPrevMessageList, clearMessage} from "../modules/chat";
import ChatEnd from "../components/ChatEnd";

const ConnectChatContainer = () => {
    const {
        connectUser: {userConnect, userInfo},
        connectChat: {chatLoading, chatLogin, chatConnect, chatStart, chatError, chatErrorMsg, connectShow, socketOn}
    } = useSelector((state) => ({
        connectUser: state.connectUser,
        connectChat: state.connectChat,
    }), shallowEqual);

    const dispatch  = useDispatch();
    /**
     * 샌드버드 로그인
     */
    useEffect(() => {
        dispatch(onChatLogin(userInfo[`${userInfo.type}`].ac_id, userInfo[`${userInfo.type}`].ac_nick, userInfo.token));
    }, []);
    /**
     * 로그인이 되었다면
     */
    useEffect(() => {
        if (chatLogin) {
            dispatch(connectStatus(true));
            if (userInfo.type === 'caller') {
                /**
                 * caller: Hermes 에서 받은 채널키값으로 채팅방을 생성한다. (공개그룹채널)
                 */
                dispatch(onCreatChat(userInfo.caller.ac_id, userInfo.callee.ac_id, userInfo.channel));
            } else {
                /**
                 * callee: Hermes 에서 받은 채널키값으로 채팅방에 참여한다.
                 */
                dispatch(onJoinChat(userInfo.channel));
            }
            /**
             * 핸들러 등록
             */
            registerGroupChannelHandler(userInfo, userInfo.channel, dispatch);
        }
    }, [chatLogin]);
    /**
     * 채팅 방생성 또는 입장등 채팅 연결이 되었다면
     */
    useEffect(() => {
        if (chatConnect) {
            /**
             * 앱실행시 안읽은 메세지가 있었다면 읽음 표시
             */
            const channelUrl = userInfo.channel;
            sbMarkAsRead({channelUrl});
            dispatch(clearMessage());
            dispatch(getPrevMessageList(channelUrl, userInfo));
        }
    }, [chatConnect]);

    useEffect(() => {
        /**
         * 상담사일 경우 채팅 커넥트가 되고 소켓 셋팅이 완료 되었다면 채팅 시작을 보냄
         */
        if (socketOn) {
            if (userInfo.type === 'callee') {
                dispatch(sendChatStart());
            }
        }
    }, [socketOn]);

    return (
        <>
            {chatLoading && <Loading onLoading={chatLoading} />}
            {chatLogin && !chatError && userConnect && connectShow && <Connect userInfo={userInfo} chatConnect={chatConnect} chatStart={chatStart} />}
            {chatError && <ChatEnd message={chatErrorMsg} />}
        </>
    );
}

export default ConnectChatContainer;