import {createAction, handleActions} from 'redux-actions';

const POPUP_SHOW    = 'popup/SHOW_POPUP';
const POPUP_CLOSE   = 'popup/POPUP_CLOSE';

export const popupShow      = createAction(POPUP_SHOW, action=>action);
export const popupClose     = createAction(POPUP_CLOSE);

const initialState = {
    popupVisible: false,
    popupStyle: '',
    popupTitle: '',
    popupMessage: '',
    popupTime: '',
    popupUseCoin:'',
};

const popup = handleActions(
    {
        [POPUP_SHOW]: (state, {payload}) => ({
            ...state,
            popupVisible: true,
            popupTitle: payload && payload.title ? payload.title : '안내',
            popupStyle: payload && payload.style ? payload.style: '',
            popupMessage: payload && payload.message ? payload.message : '채팅을 이용하실 수 없습니다.',
            popupTime: (payload && payload.time) ? payload.time : state.popupTime,
            popupUseCoin: (payload && payload.useCoin) ? payload.useCoin : state.useCoin,
        }),
        [POPUP_CLOSE]: (state) => ({
            ...state,
            popupVisible: false,
            popupStyle: '',
            popupTitle: '',
            popupMessage: '',
            popupTime: '',
            popupUseCoin: '',
        }),
    },
    initialState
);

export default popup;
