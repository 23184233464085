import {createAction, handleActions} from 'redux-actions';
import * as hermes from '../api/hermes';
import * as artemis from '../api/artemis';
import {setDuration, setEndTime, setStartTime, setUseCoin} from "./connectChat";
import {decrypt} from "../lib/secureCrypto";

/*
* 유저 정보
*/
const GET_USER          = 'connectUser/GET_USER';
const GET_USER_SUCCESS  = 'connectUser/GET_USER_SUCCESS';
const GET_USER_ERROR    = 'connectUser/GET_USER_ERROR';
const SET_REDIRECT_URL  = 'connectUser/SET_REDIRECT_URL';
const SET_ISAPP         = 'connectUser/SET_ISAPP';

export const setRedirectUrl = createAction(SET_REDIRECT_URL, redirectUrl=>redirectUrl);
export const setIsApp   = createAction(SET_ISAPP, isApp=>isApp);
export const getUserInfo_test = (token) => async dispatch => {
    try {
        const user = JSON.parse(decrypt(token));
        if (!user.hasOwnProperty('channel') || !user.hasOwnProperty('ac_id') || !user.hasOwnProperty('st_code')) {
            dispatch({type: GET_USER_ERROR, userErrorMsg: '채팅을 이용하실 수 없습니다.????'});
        } else {
            dispatch({type: GET_USER_ERROR, userErrorMsg: '네트워크가 불안정하여 <br />연결할 수 없습니다. <br />다시 시도해 주세요.'});
        }
    } catch (e) {
        dispatch({type: GET_USER_ERROR, userErrorMsg: '채팅을 이용하실 수 없습니다.'});
    }
}
export const getUserInfo = (token) => async dispatch => {
    try {
        const user = JSON.parse(decrypt(token));
        if (!user.hasOwnProperty('channel') || !user.hasOwnProperty('ac_id') || !user.hasOwnProperty('st_code') || !user.isapp) {
            dispatch({type: GET_USER_ERROR, userErrorMsg: '채팅을 이용하실 수 없습니다.'});
        } else {
            console.log('isapp setting::', user.isapp);
            dispatch(setRedirectUrl(user.redirect_url));
            dispatch(setIsApp(user.isapp));
            dispatch({type: GET_USER});
            try {
                const response = await hermes.getUserInfo(user);
                if (response.response === 'success') {
                    dispatch({type: GET_USER_SUCCESS, userInfo:response.data});
                    if (response.data.startTime && response.data.startTime !== '' && response.data.endTime !== '') {
                        /* 채팅창 여러개 띄우기 방지
                        * 채팅 온라인인지 오프라인인지 상태 확인
                        * 이건 적용하려면  테스트를 해야함..
                        * 일단 오류는 종료시 sb.disconnect 하면 간혹 에러, 접속할때도 네트워크 문제로 바로바로 캐치를 못하는듯
                        *  */
                        /*try {
                            const isOnline = await artemis.checkOnline(user.channel, user.ac_id);
                            console.log(isOnline);
                            if (isOnline) {
                                dispatch({type: GET_USER_ERROR, userErrorMsg: '채팅을 이용하실 수 없습니다.<br>현재 로그인 중인 채팅 창이 있습니다.'});
                                return false;
                            }
                        } catch (e) {
                            dispatch({type: GET_USER_ERROR, userErrorMsg: '채팅을 이용하실 수 없습니다.'});
                            return false;
                        }*/
                        dispatch(setStartTime(response.data.startTime));
                        dispatch(setEndTime(response.data.endTime));
                    }
                    if (response.data.duration) {
                        dispatch(setDuration(response.data.duration));
                    }
                    if (response.data.useCoin) {
                        dispatch(setUseCoin(response.data.useCoin));
                    }
                } else {
                    dispatch({type: GET_USER_ERROR, userErrorMsg: '채팅을 이용하실 수 없습니다.'});
                }
            } catch (e) {
                dispatch({type: GET_USER_ERROR, userErrorMsg: '네트워크가 불안정하여 <br />연결할 수 없습니다. <br />다시 시도해 주세요.'});
                /*if (e.message) {
                    dispatch({type: GET_USER_ERROR, userErrorMsg: e.message});
                } else {
                    dispatch({type: GET_USER_ERROR, userErrorMsg: e});
                }*/
            }
        }
    } catch (e) {
        dispatch({type: GET_USER_ERROR, userErrorMsg: '채팅을 이용하실 수 없습니다.'});
    }
}

const initialState = {
    userLoading: true,
    userConnect: false,
    userInfo: {
        type: null,
        st_code: null,
        channel: null,
        token: null,
        useCoin:0,
        duration: 0,
        callee: {
            it_category: null,
            it_coin_price: null,
            ac_nick: null,
            ac_id: null,
            icon_url: null,
        },
        caller: {
            ac_nick: null,
            ac_id: null,
        },
        startTime: '',
        endTime: '',
    },
    redirectUrl:'',
    isApp: 'N',
    userError: false,
    userErrorMsg: null,
};

const connectUser = handleActions(
    {
        [GET_USER]: (state) => ({
            ...state,
            userLoading: true,
        }),
        [GET_USER_SUCCESS]: (state, action) => ({
            ...state,
            userLoading: false,
            userConnect: true,
            userInfo: action.userInfo
        }),
        [GET_USER_ERROR]: (state, action) => ({
            ...state,
            userLoading: false,
            userConnect: false,
            userError: true,
            userErrorMsg: action.userErrorMsg
        }),
        [SET_ISAPP]: (state, {payload: isApp}) => ({
           ...state,
           isApp: isApp,
        }),
        [SET_REDIRECT_URL]: (state, {payload: redirectUrl}) => ({
            ...state,
            redirectUrl: (redirectUrl) ? redirectUrl : ''
        })
    },
    initialState,
);

export default connectUser;

