const axios = require('axios');
const dotenv = require('dotenv');

dotenv.config();

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
    //baseURL: 'http://localhost:8080',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});

export default instance;