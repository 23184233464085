import SendBird from "sendbird";
import {sbMarkAsRead, sbIsTyping} from "../modules/chat";
import {channelDelete} from "../modules/connectChat";

export const registerGroupChannelHandler = (userInfo, channelUrl, dispatch) => {
    const sb = SendBird.getInstance();
    let channelHandler = new sb.ChannelHandler();
    /**
     * 채팅방 입장
     * @param channel
     * @param user
     */
    channelHandler.onUserJoined = (channel, user) => {
        if (channel.url === channelUrl && userInfo.type === 'caller') {
            if (userInfo.callee.ac_id === user.userId) {
                dispatch({ type: 'connectChat/CHAT_CONNECT_SUCCESS' });
            }
        }
    };
    /**
     * 채팅방 삭제 :: api 서버에서 삭제
     * 채팅방 삭제 1. 채팅 시간 종료, 2. 본인종료, 3. 상담사 종료, 4. 상담사 채팅 거절
     * 조건 전부 Hermes 에서 artemis Api 를 통해 삭제를 시킨다.
     * 삭제 핸들러가 감지되면 채팅에서 Hermes 에 상담데이터가 있는지 확인한다.
     * 데이터가 있다면 상담종료 안내 팝업을 띄우고 종료.
     * 없다면 종료.
     * @param channel
     * @param channelType
     */
    channelHandler.onChannelDeleted = (channel, channelType) => {
        if (channel === channelUrl) {
            dispatch(channelDelete(channelUrl));
        }
    };
    /**
     * 채팅방 나가기 (회원정용 상담사는 방삭제임) 사용안함
     * @param channel
     * @param user
     */
    channelHandler.onUserLeft = (channel, user) => {
        if (channel.url === channelUrl) {
           // dispatch({ type: 'connectChat/CHAT_CLOSE' });
        }
    };
    /**
     * 타이핑
     * @param channel
     */
    channelHandler.onTypingStatusUpdated = channel => {
        if (channel.url === channelUrl) {
            const memberTyping = sbIsTyping(channel);
            dispatch({
                type: 'chat/TYPING_STATUS_UPDATED',
                payload: memberTyping
            });
        }
    };
    /**
     * 그룹채널 메세지 수신
     * @param channel
     * @param message
     */
    channelHandler.onMessageReceived = (channel, message) => {
        if (channel.url === channelUrl) {
            if (channel.isGroupChannel()) {
                sbMarkAsRead({ channel });
            }
            if (message.hasOwnProperty('_sender')) {
                message.user = (message._sender.userId === userInfo.callee.ac_id) ? 'callee' : 'caller';
                message.readMessage = true;
                dispatch({
                    type: 'chat/MESSAGE_RECEIVED',
                    payload: message
                });
                dispatch({
                    type: 'chat/SCROLL_DOWN'
                });
            }
        }
    };
    /**
     * 그룹채널 메세지 업데이트 사용안함
     * @param channel
     * @param message
     */
    channelHandler.onMessageUpdated = (channel, message) => {
        if (channel.url === channelUrl) {
            console.log('onMessageUpdated', message);
            /*dispatch({
                type: MESSAGE_UPDATED,
                payload: message
            });*/
        }
    };
    /**
     * 그룹채널 메세지 삭제 사용안함
     * @param channel
     * @param messageId
     */
    channelHandler.onMessageDeleted = (channel, messageId) => {
        if (channel.url === channelUrl) {
            dispatch({
                type: 'chat/MESSAGE_DELETED',
                payload: messageId
            });
        }
    };
    /**
     * 메세지를 읽었는지
     * @param channel
     */
    channelHandler.onReadReceiptUpdated = (channel) => {
        if (channel.url === channelUrl) {
            dispatch({ type: 'chat/READ_RECEIPT_UPDATED' });
        }
    };
    sb.addChannelHandler(channelUrl, channelHandler);
};
