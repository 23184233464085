import React, {useEffect} from 'react';
import AlertPopup from "./popup/AlertPopup";
import {sleep} from "../lib/util";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import * as sendbird from '../api/sendbird';
import Loading from "./Loading";

const ChatEnd = ({message}) => {
    const redirectUrl = useSelector(state => state.connectUser.redirectUrl, shallowEqual);
    const isApp = useSelector(state => state.connectUser.isApp, shallowEqual);
    const chatLoading = useSelector(state => state.connectChat.chatLoading, shallowEqual);

    const dispatch = useDispatch();
    useEffect(() => {

        const redirect = async () => {
            try {
                await sendbird.sbDisconnect();
            } catch (e) {
            }
            await sleep(3000);
        }
        if (redirectUrl !== '') {
            dispatch({type: 'connectChat/LOADING'});
            if (isApp === 'Y') {
                try {
                    window.AppConnector.chatClose(redirectUrl);
                } catch (e) {}
            } else {
                redirect().then(() => {
                    window.location.href = redirectUrl;
                }).catch(() => {
                    window.location.href = redirectUrl;
                });
            }
        }
    }, []);
    return (
        <>
            { chatLoading && <Loading />}
            <div>
                <AlertPopup message={message} />
            </div>
        </>
    );
};

export default ChatEnd;
