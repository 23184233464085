import React from 'react';
import {Route} from 'react-router-dom';
import App from './App';

import Design from './design/Design';

const Router = () => {
    return (
        <>
           {/* <Route path={['/', '/callee', '/caller']} component={App} exact={true} />*/}
            <Route path={['/', '/:token']} component={App} exact={true} />
        </>
    );
};

export default Router;