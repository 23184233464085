import React, {useCallback, useEffect} from "react";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import ConnectUserContainer from "./containers/ConnectUserContainer";
import ConnectChatContainer from "./containers/ConnectChatContainer";
import ChatContainer from "./containers/ChatContainer";
import ChatPopupContainer from "./containers/ChatPopupContainer";
import {adminMessage} from "./modules/chat";
import ChatEnd from "./components/ChatEnd";
import TimeExtension from "./containers/TimeExtensionContainer";
import {setStartTime, setEndTime, setDuration, setUseCoin, setSocketOn, setChatStart, chatCloseDisconnect} from "./modules/connectChat";
import * as hermes from './api/hermes';
import socket from './lib/socket';
import './assets/css/common.css';
import './assets/css/chat.css';

const App = ({match}) => {
    const token = match.params.token;
    const {userConnect, userInfo} = useSelector(state => state.connectUser, shallowEqual);
    const {chatConnect, chatStart, chatClose, chatInfo, chatCreatSuccess} = useSelector(state => state.connectChat, shallowEqual);
    const {popupVisible} = useSelector(state => state.popup, shallowEqual);
    const {timeExtensionVisible} = useSelector(state => state.timeExtension, shallowEqual);

    const dispatch = useDispatch();
    const onCloseChat = useCallback(() => {
        dispatch(chatCloseDisconnect());
    }, [dispatch]);
    /**
     * 관리자 메세지 및 시간 셋팅
     * @type {function(*=): void}
     */
    const onAdminMessage = useCallback((data) => {
        dispatch(setStartTime(data.startTime));
        dispatch(setEndTime(data.endTime));
        dispatch(setDuration(data.duration));
        dispatch(setUseCoin(data.useCoin));
        dispatch(adminMessage(data));
    }, [dispatch]);
    /**
     * 소켓 셋팅
     * 관리자 멘트전송, 종료시간 (채팅 시작시 종료시간, 시간 연장시 종료시간) 클라이언트 소켓 셋팅
     */
    const sleep = n => new Promise(resolve=>setTimeout(resolve, n));
    useEffect(() => {
        if (chatCreatSuccess) {
            socket.auth = {sessionID: chatInfo.channel.url};
            socket.connect();
            socket.on('connect', async () => {
                if (socket.connected) {
                    /* 처음 한번만 caller 가 소켓 연결완료를 Hermes 에 알려준다
                    * - 상담사에게 push 보내기위해
                    * 소켓 셋팅이 안된 상태에서 push 가 날라가고 상담사가 접속하게 되면 채팅 시작 멘트가 안옴
                    * */
                    if (userInfo.type === 'caller' && userInfo.startTime === '' && userInfo.endTime === '') {
                        try {
                            await hermes.sendStandby(userInfo.channel);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    dispatch(setSocketOn());
                }
            });
            socket.on('connect_error', e => {
                /* 소켓 에러시 처리에 대해서는 논의 해야함
                * console.log('connect_error', e.message);
                * socket.connect();
                * socket.disconnect();
                * */
            });
            socket.on('chat message', (data) => {
                dispatch(setChatStart());
                onAdminMessage(data);
            });
            socket.on('chat disconnect', (data) => {
                if (data.channel === chatInfo.channel.url && data.ac_id === chatInfo.sb.userId) {
                    // 앱뷰 뒤로가기에서 disconnect socket api 호출
                    onCloseChat();
                }
            });

            return () => socket.disconnect();
        }
    }, [chatCreatSuccess]);
    return (
        <>
            {!chatClose && <ConnectUserContainer token={token}/>}
            {!chatClose && userConnect && <ConnectChatContainer />}
            {!chatClose && chatConnect && chatStart && <ChatContainer />}
            {!chatClose && popupVisible && <ChatPopupContainer />}
            {timeExtensionVisible && <TimeExtension />}
            {chatClose && <ChatEnd />}
        </>
    );
}

export default App;
