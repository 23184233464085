import React, {useCallback} from 'react';
import TimeExtension from "../components/TimeExtension";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {closePopup, setTimeExtension} from "../modules/timeExtension";
import {popupShow} from "../modules/popup";

const setDurationTime = [
    {key: 'dur300', text: '5분', duration: 300},
    {key: 'dur600', text: '10분', duration: 600},
    {key: 'dur1200', text: '20분', duration: 1200},
    {key: 'dur1800', text: '30분', duration: 1800},
    {key: 'dur3000', text: '60분', duration: 3600}
];

const TimeExtensionContainer = () => {
    const myCoin = useSelector(state => state.timeExtension.myCoin, shallowEqual);
    const it_coin_price = useSelector(state => state.timeExtension.itCoinPrice, shallowEqual);
    const chatInfo = useSelector(state => state.connectChat.chatInfo, shallowEqual);

    const dispatch = useDispatch();
    const onPopupClose = useCallback(() => {
        dispatch(closePopup());
    }, [dispatch]);
    const onTimeExtension = useCallback((data) => {
        if (data.useCoin > myCoin) {
            dispatch(popupShow({title:'상담시간 연장', style: 'timeExtensionError', message: ''}));
        } else {
            dispatch(setTimeExtension(chatInfo.channel.url, chatInfo.sb.userId, data.duration, data.useCoin));
        }
    }, [dispatch]);

    return (
        <TimeExtension it_coin_price={it_coin_price} setDurationTime={setDurationTime} myCoin={myCoin} onPopupClose={onPopupClose} onTimeExtension={onTimeExtension} />
    );
}
export default TimeExtensionContainer;